<template>
  <el-dialog title="" :visible.sync="dialogVisible" width="80%">
    <div class="view-columns">
      <div class="view-column">
        <div class="column-title">原图</div>
        <div class="column-image">
          <img class="column-image" :src="ocrSourceUrl">
        </div>
      </div>
      <div class="view-column view-column-split">
        <div class="column-title">|</div>
        <div class="column-split"></div>
      </div>
      <div class="view-column">
        <div class="column-title">识别结果</div>
        <div class="iframe-container">
          <div :id="`container-${tabName}`" class="no-select" @contextmenu.prevent @copy.prevent="preventCopy">
            <!-- <iframe class="column-result" :src="ocrResultUrl"></iframe> -->
          </div>
        </div>
        <div class="column-text">
          <div v-if="ocrWordUrl" class="bottom-btns" @click="handleDocDownload"><span class="btn">导出Word</span></div>
        </div>

        <div v-if="!ocrWordUrl && !file.ocrInProgress" class="btn-ocr" @click="handleOrc(file)">开始识别</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    tabName: String
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        console.log("dialogVisible")
        this.ocrWordUrl = '';
        const container = document.getElementById(`container-${this.tabName}`);
        if (container) {
          container.innerHTML = ""; // 清空容器内容
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      docViewUrl: "https://view.officeapps.live.com/op/view.aspx?src=",//"https://view.xdocin.com/view?src="
      ocrSourceUrl: '',
      ocrWordUrl: '',
      ocrResultUrl: '',
      showMessageError: false,
      file: {
        ocrInProgress: false
      },
    }
  },
  methods: {
    init(file) {
      this.showMessageError = false;
      this.dialogVisible = true;
      this.file = file;

      this.$nextTick(() => {
        this.ocrSourceUrl = file.fileUrl || file.url;
        const resultUrl = file.resultUrl;
        if (!resultUrl) {
          console.log('Word url null');
        }
        this.ocrWordUrl = resultUrl;
        this.renderDocx(resultUrl);
      })
    },
    async renderDocx(docxUrl) {
      if (!docxUrl) return;

      let response = await fetch(docxUrl);
      if (!response.ok) {
        this.$message.error("文件读取失败");
      }

      const docData = await response.blob();
      console.log("docData", docData)
      const container = document.getElementById(`container-${this.tabName}`);
      console.log("document.getElementById", container);
      docx.renderAsync(docData, container).then(() => console.log("docx: finished"));
    },
    handleDocDownload() {
      window.open(this.ocrWordUrl);
    },
    preventCopy(event) {
      event.preventDefault(); // 阻止复制行为
      this.$message.error('复制操作被禁止');
      const clipboardData = event.clipboardData || window.clipboardData;
      clipboardData.setData('text/plain', '复制被禁止！');
    },
    handleOrc(file) {
      this.$emit('orc', file);
    }
  }
}
</script>

<style scoped>
.view-columns {
  /* height: 50vh; */
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-start;
  align-items: flex-start;
}

.view-column {
  width: 49.75%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.view-column-split {
  width: .5%;

}

.column-title {
  background: #0769F6;
  width: 100%;
  height: 60px;
  color: #fff;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.column-split {
  width: 1px;
  height: calc(95vh - 60px);
  background-image: -webkit-linear-gradient(top, #979797, #979797 50%, transparent 0);
  background-image: linear-gradient(180deg, #979797 0, #979797 50%, transparent 0);
  background-size: 1px 8px;
  background-repeat: repeat-y;
  background-position: 50%;
}

.column-image {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  max-height: calc(95vh - 39px);
  overflow-y: scroll;
  scrollbar-width: none;
  object-fit: contain;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: calc(95vh - 39px);
  overflow: auto;
}

.iframe-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari 和新版本的Edge */
}

.column-result {
  width: 100%;
  height: calc(95vh - 39px);

  position: absolute;

  /* Adjust this value to hide the toolbar */
  left: 0;
  width: 100%;
  top: -90px;
  height: calc(100% + 90px);

  /* top: 0px;
    height: 100%; */
  /* Adjust this value to hide the toolbar */
  border: none;
}

.bottom-btns {
  cursor: pointer;
  width: 120px;
    height: 48px;
    background: #0769F6;
    border-radius: 10px;

  position: absolute;
  bottom: 30px;
  right: 30px;

  z-index: 9999;

  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 48px;
    text-align: center;
    font-style: normal;
}

.no-select {
  -webkit-user-select: none;
  /* Chrome, Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by most browsers */
}

.btn-ocr {
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  width: 400px;
    height: 50px;
    background: #0769F6;
    border-radius: 10px;

    font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 50px;
      text-align: center;
      font-style: normal;
}
</style>