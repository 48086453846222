<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="tip-text">普通用户单日免费转换次数为5次<br>升级VIP会员即可享受无限制转换次数</div>

      <div class="btn-do-reg" @click="onPurchaseVip">升级会员</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    init() {
      this.visible = true;
    },
    onPurchaseVip() {
      this.visible = false;
      this.$emit('purchase');
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}

/deep/ .el-dialog__header {
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 480px;
}

/deep/ .el-dialog__headerbtn {
  font-size: 37px;
  background: #000;
  right: -60px;
  top: 0;
  color: #fff;
  width: 60px;
  height: 60px;
}

/deep/ .el-dialog__close:hover {
  color: #fff;
}

/deep/ .el-dialog__close {
  color: #fff;
}

.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.tip-text {
  margin-top: 103px;
  padding: 0 150px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
}

.btn-do-reg {
  width: 480px;
  height: 60px;
  margin-top: 50px;
  background: #fff;
  border: 1px solid #086AF6;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #086AF6;
}
</style>