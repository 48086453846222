<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <el-form ref="dataForm" :rules="rules" :model="form">
        <el-form-item prop="content" class="input-wrap">
          <el-input type="textarea" resize="none" v-model="form.content" placeholder="请输入您想反馈的问题"></el-input>
        </el-form-item>
      </el-form>
      <div class="title">客服微信：kefuhao7 工作时间：周一至周五 9:00-18:00</div>

      <div class="btn-do-login" @click="onFeedback">提交</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form: {
        content: ''
      },
      rules: {
        content: [
          { required: true, message: '请输入您想反馈的问题', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true;
      this.form = {
        content: ''
      };

      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      })
    },
    onFeedback() {
      this.$refs.dataForm.validateField('content', (valid) => {
        console.log('valid', valid, typeof valid)
        if (!valid) {
          this.sendFeedBack();
        }
      });
    },
    async sendFeedBack() {
      let res = await this.$api.sendFeedBack(this.form);
      if (res.data.code == 0) {
        this.$message({
          message: '提交成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.visible = false
          }
        })
      }
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}

/deep/ .el-dialog__header {
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 500px;
}

/deep/ .el-dialog__headerbtn {
  font-size: 37px;
  background: #000;
  right: -60px;
  top: 0;
  color: #fff;
  width: 60px;
  height: 60px;
}

/deep/ .el-dialog__close:hover {
  color: #fff;
}

/deep/ .el-dialog__close {
  color: #fff;
}

.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.title {
  margin-top: -20px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 30px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}

.input-wrap {
  margin-top: 42px;
  width: 677px;
  height: 298px;
  background: #F7F7F7;
}

/deep/ .el-form-item__content, .el-textarea {
  width: 100%;
  height: 100%;
}
/deep/ .el-textarea__inner {
  background: #F6F6F6;
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 27px 30px;

  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #9A9A9A;
line-height: 22px;
font-style: normal;
}
</style>