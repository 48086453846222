<template>
  <el-tabs v-model="activeTab" @tab-click="handleTabClick" tab-position="left" style="height: 200px;width: 222px;">
    <el-tab-pane label="PDF转WORD" name="pdf"></el-tab-pane>
    <el-tab-pane label="图像转WORD" name="image"></el-tab-pane>
    <!-- <el-tab-pane label="数学公式识别"></el-tab-pane>
      <el-tab-pane label="电子表格识别"></el-tab-pane>-->
  </el-tabs>
</template>


<script>
export default {
  name: 'SidebarTabs',
  props: {
    tabSelected: Function,
  },
  data() {
    return {
      activeTab: 'pdf', // 默认选中的 Tab
    };
  },
  methods: {
    handleTabClick(tab) {
      this.$emit('tab-selected', tab);
    }
  }
}
</script>

<style scoped>

/* /deep/ .el-tabs--left .el-tabs__header.is-left,
.el-tabs--left .el-tabs__header.is-right,
.el-tabs--left .el-tabs__nav-scroll,
.el-tabs--left .el-tabs__nav-wrap.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-right,
.el-tabs--right .el-tabs__header.is-left,
.el-tabs--right .el-tabs__header.is-right,
.el-tabs--right .el-tabs__nav-scroll,
.el-tabs--right .el-tabs__nav-wrap.is-left,
.el-tabs--right .el-tabs__nav-wrap.is-right
{
  width: 100%;
} */
/deep/ .el-tabs__nav-wrap::after{
  width: 0;
  background: #fff;
}

/deep/ .el-tabs__item {
  padding: 0;
  width: 222px;
  height: 56px;
  border-radius: 3px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
  line-height: 56px;
  text-align: center;
  font-style: normal;
  position: relative;
}

/deep/ .el-tabs__item:hover {
  color: #0769F6;
  cursor: pointer;
}

/deep/ .el-tabs__item.is-active {
  color: #fff;
  background: #0769F6;
}

/deep/ .el-tabs__item {
  padding: 0 60px;

}

/deep/ .el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}

/deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after,
.el-tabs--left .el-tabs__nav-wrap.is-right::after,
.el-tabs--right .el-tabs__nav-wrap.is-left::after,
.el-tabs--right .el-tabs__nav-wrap.is-right::after {
  width: 0px;
}

/deep/ .el-tabs__active-bar {
  background-color: transparent;
}
</style>