<template>
  <div class="sidebar">
    <div v-if="announcements.length > 0" class="announcement-wrap" @click="handleAnnouncement">
      <el-carousel height="50px" autoplay arrow="never" :interval="10000" indicator-position="none"
        @change="changeAnnouncement">
        <el-carousel-item v-for="(item, index) in announcements" :key="index">
          <div class="announcement-text">公告: {{ item.content }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="logo-wrap">
      <img class="logo" src="../assets/logo.png" alt="">
      <div class="logo-text">万能文档转换器</div>
    </div>

    <SidebarTabs @tab-selected="handleTabClick"></SidebarTabs>

    <div class="login-wrap">
      <div v-if="!logined" class="login-text" @click="handleLogin">点击登录</div>
      <div v-else class="userinfo-wrap">
        <div class="username">{{ userInfo.account }}</div>
        <div class="userlevel">
          <img class="userlevel-img"
            :src="userInfo.vipLevel == 0 ? require('@/assets/img/vip0.png') : require('@/assets/img/vip1.png')"
            alt="VIP">
          <div v-if="userInfo.vipLevel == 0" class="userlevel-upgrade" @click="handlePurchaseVip">立即升级</div>
          <div v-else class="userlevel-upgrade renewal" @click="handlePurchaseVip">续费</div>
        </div>
        <div v-if="userInfo.vipLevel == 0" class="userlevel-count">当日剩余次数：{{ userInfo.dayUseCount }}</div>
        <div v-else class="userlevel-count">到期日期：{{ userInfo.vipEndTimeFormat }}</div>
      </div>
      <div class="about-wrap">
        <div @click="handleAboutUs">关于我们</div>
        <div>|</div>
        <div @click="handleFeedback">意见反馈</div>
      </div>
    </div>

    <UserLogin ref="userLogin" v-if="userLoginVisible" @register="handleUserRegister" @success="loginSuccess">
    </UserLogin>
    <UserRegister ref="userRegister" v-if="userRegisterVisible" @login="handleLogin" @success="loginSuccess">
    </UserRegister>
    <PurchaseVip ref="purchaseVip" v-if="purchaseVipVisible" @logout="handleLogout" @purchase="loginSuccess">
    </PurchaseVip>
    <Feedback ref="feedback" v-if="feedbackVisible"></Feedback>
    <TipLogin ref="tipLogin" v-if="tipLoginVisible" @login="handleLogin" @register="handleUserRegister"></TipLogin>
    <TipPurchase ref="tipPurchase" v-if="tipPurchaseVisible" @purchase="handlePurchaseVip"></TipPurchase>
    <Announcement ref="announcement" v-if="announcementVisible"></Announcement>
  </div>
</template>

<script>
import UserLogin from './UserLogin.vue';
import UserRegister from './UserRegister.vue'
import PurchaseVip from './PurchaseVip.vue';
import Feedback from './Feedback.vue';
import SidebarTabs from './SidebarTabs.vue';
import TipLogin from  './TipLogin.vue';
import TipPurchase from './TipPurchase.vue';
import Announcement from './Announcement.vue';

export default {
  name: 'SideBar',
  components: {
    UserLogin,
    UserRegister,
    PurchaseVip,
    Feedback,
    SidebarTabs,
    TipLogin,
    TipPurchase,
    Announcement
  },
  data() {
    return {
      userInfo: {
        account: '',
        vipLevel: 0,
        dayUseCount: 0,
        vipEndTime: '',
        vipEndTimeFormat: ''
      },
      logined: false,
      userLoginVisible: false,
      userRegisterVisible: false,
      purchaseVipVisible: false,
      feedbackVisible: false,
      tipLoginVisible: false,
      tipPurchaseVisible: false,
      firstCreated: false,
      announcements: [
      //   {
      //   content: "欢迎使用万能文档转换器，请及时关注公告。"
      // }, {
      //   content: "公告一：系统维护，预计持续到晚上10点"
      //   }, {
      //   content: "公告二：请及时更新资料，以免影响使用。"
      //   }
      ],
      announcementVisible: false,
      curAnnouncementIndex: 0,
    };
  },
  created(options) {
    // console.log("token", this.$cookies.get('token'));
    if (this.$cookies.get('token')) {
      this.logined = true;
      this.getUserInfo();
    } else {
      this.addPV(0);
    }

    this.getNoticeList();
  },
  methods: {
    handleTabClick(tab) {
      this.$emit('tab-selected', tab.name);
    },
    handleLogin() {
      console.log("handleLogin")
      this.userLoginVisible = true
      this.$nextTick(() => {
        this.$refs.userLogin.init();
      })
    },
    handleUserRegister() {
      console.log("handleUserRegister")
      this.userRegisterVisible = true
      this.$nextTick(() => {
        this.$refs.userRegister.init();
      })
    },
    handlePurchaseVip() {
      console.log("handlePurchaseVip")
      this.purchaseVipVisible = true
      this.$nextTick(() => {
        this.$refs.purchaseVip.init(this.userInfo);
      })
    },
    handleAboutUs() {
      console.log("handleAboutUs")
      window.open("https://www.qizhixinxi.com#user", "_blank");
    },
    handleFeedback() {
      console.log("handleFeedback")
      this.feedbackVisible = true
      this.$nextTick(() => {
        this.$refs.feedback.init();
      })
    },
    handleLogout() {
      console.log("handleLogout");
      this.logined = false;
    },
    handleAnnouncement() {
      console.log("handleAnnouncement");
      let text = this.announcements[this.curAnnouncementIndex].content;
      text = text ? text : '';
      this.announcementVisible = true;
      this.$nextTick(() => {
        this.$refs.announcement.init(text);
      });
    },
    changeAnnouncement(e) {
      console.log("changeAnnouncement", e);
      this.curAnnouncementIndex = e;
    },
    showTipLogin() {
      this.tipLoginVisible = true;
      this.$nextTick(() => {
        this.$refs.tipLogin.init();
      })
    },
    showTipPurchase() {
      this.tipPurchaseVisible = true;
      this.$nextTick(() => {
        this.$refs.tipPurchase.init();
      })
    },
    /*
    "info": {
      "uid": 2,
      "lastLoginTime": "2024-09-19 11:53:17",
      "vipLevel": 0
    }*/
    loginSuccess(info) {
      this.logined = true;

      this.getUserInfo();
    },
    async getUserInfo() {
      let res = await this.$api.getUserInfo();
      if (res.data.code == 0) {
        this.userInfo = res.data.info;
        if (this.userInfo.vipEndTime) {
          const vipEndTime = this.userInfo.vipEndTime;
          this.userInfo.vipEndTimeFormat = vipEndTime.split(' ')[0];
        }
        this.$H.userInfo = res.data.info;
        console.log("getUserInfo res");
        if (!this.firstCreated) {
          this.firstCreated = true;
          this.addPV(this.userInfo.uid);
        }
      } else if (res.data.code === 401) {
        this.logined = false;
        this.showTipLogin();
      }
    },
    async addPV(uid) {
      this.firstCreated = true;
      let res = await this.$api.addPV({uid: uid});
      if (res.data.code == 0) {
        // console.log("addPV", res.data);
      }
    },
    async getNoticeList() {
      let res = await this.$api.getNoticeList({page: 1, limit: 1});
      if (res.data.code == 0) {
        this.announcements = res.data.page.list;
      }
    },
    
  },
}
</script>

<style scoped>
.sidebar {
  position: relative;
  width: 212px;
  background-color: #FFFFFF;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
  height: 40px;
}

.sidebar a {
  height: 40px;
}

.sidebar a.is-active {
  background: #0769F6;
  color: #fff;
  padding: 10px 40px;
}

.sidebar a.is-active:before {
  content: " ";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-width: 1.3vh 0 1.3vh 2vh;
  border-style: solid;
  border-color: transparent transparent transparent #0769F6;
}

.announcement-wrap {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.announcement-text {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  text-decoration: underline;
  color: #0769F6;
  
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo-wrap {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 98px;
  height: 80px;
  /* border-radius: 50%; */
  /* background: #0769F6; */
}

.logo-text {
  margin-top: 13px;
  margin-bottom: 102px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
}

.login-wrap {
  position: absolute;
  bottom: 81px;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.login-text {
  cursor: pointer;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #0769F6;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.userinfo-wrap {
  
}

.username {
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.userlevel {
  margin: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.userlevel-img {
  margin-right: 15px;
  width: 100px;
  height: 32px;
}

.userlevel-upgrade {
  width: 60px;
    height: 25px;
    background: linear-gradient(299deg, #FF823A 0%, #FF8787 100%);
    border-radius: 3px;

  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 25px;
    text-align: center;
    font-style: normal;
}

.renewal {
  cursor: pointer;
  background: linear-gradient(277deg, #4051FF 0%, #5CF2FF 100%);
}

.userlevel-count {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #8E8E8E;
    line-height: 20px;
    text-align: center;
    font-style: normal;
}

.about-wrap {
  cursor: pointer;
  top: 20px;
    position: relative;
  margin-top: 19px;
font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #1E77F6;
  line-height: 22px;
  text-align: center;
  font-style: normal;

  display: flex;
  justify-content: space-around;
  width: 150px;
}


.btn-login {
  position: absolute;
  bottom: 60px;
}

.is-login {
  display: flex;
  align-items: center;

}
.login-icon {
  font-size: 40px;
  /* margin-left: -40px; */
}

.btn-loginout {
  border: 1px solid #979797;
    background: #d8d8d8;
    border-radius: .3em;
    font-size: 1em;
    padding: .4em 2em;
    margin-top: 11em;
    margin-bottom: 2em;
    cursor: pointer;
}

/deep/ .el-loading-spinner .path {
  stroke: #0769F6;
  /* 设置新的加载动画颜色 */
}

/deep/ .el-loading-spinner .el-loading-text {
  color: #0769F6;
}

/deep/ .docx-wrapper {
  background: transparent;
}

/deep/ .docx-wrapper>section.docx {
  box-shadow: 0 0 0 0;
}

</style>