<template>
  <div class="upload-area">
    <Upload ref="uploadRef" v-loading="loading" :element-loading-text="loadingText"
      element-loading-background="rgba(0, 0, 0, 0.2)" class="upload-demo" drag multiple list-type="picture"
      :file-list="fileList" :auto-upload="false" action="" :on-preview="handlePreview" :on-change="handleChange"
      :on-remove="handleRemove" :on-exceed="handleExceed" :before-upload="handleBeforeUpload" :limit="limitCount"
      :show-file-list="showFileList" :on-picocr="handlePicOcr" :on-picedit="handlePicEdit"
      :accept="activeTab == 'image' ? 'image/jpeg,image/png,image/bmp,image/svg+xml' : 'application/pdf'">
      <div v-show="fileList.length == 0" class="tip-upload">
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text">{{ activeTab == 'image' ? "点击下方按钮选择图片 / 将图片拖入此框，一次最多同时上传100张" : "点击下方按钮选择 PDF / 将 PDF 拖入此框，一次仅支持转换一个文件"}}
        </div>
        <div class="el-upload__text">普通会员每日可免费转换5次，VIP会员不限制
        </div>
      </div>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }" @click.stop="handleStartOCR">{{ btnTxt }}
      </div>

      <div class="radio-wrap">
        <el-radio-group v-model="radio" @input="handleRadioChange" :disabled="!fileList.length">
          <el-radio-button label="正序"></el-radio-button>
          <el-radio-button label="倒序"></el-radio-button>
          <el-radio-button label="清空"></el-radio-button>
        </el-radio-group>
      </div>
      <!-- <div v-show="ocrSuccess" class="btn-ocr reset btn-ocr-active " @click.stop="handleResetOCR">重置
      </div> -->
    </Upload>

    <PreviewDocx ref="previewDocx" v-if="previewDocxVisible" :tabName="tabName" @orc="handlePreviewPicOcr"></PreviewDocx>
    <ImageEditModal v-if="showModal" :show="showModal" :imageUrl="imageSrc" @close="closeModal"
      @cropped="handleCroppedImage" />
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from './upload';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import Compressor from 'compressorjs';
import ImageEditModal from './ImageEditModal.vue';
import PreviewDocx from './PreviewDocx.vue';

const OSS = require('ali-oss');
const MAX_IMAGE = 100;

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }

  return fmt;
}

export default {
  components: {
    Upload,
    ImageEditModal,
    PreviewDocx
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "开始识别",
      dialogVisible: false,
      loading: false,
      loadingText: '',
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [
        // { 
        // name: 'food.jpeg', 
        // url: 'https://otisimg.oss-cn-hangzhou.aliyuncs.com/tmp/5_1654_2338.jpg' 
        // }
      ],
      files:[
        {
          "fileUrl": "https://otisimg.oss-cn-hangzhou.aliyuncs.com/tmp/5_1654_2338.jpg",
          "progress": 0,
          "resultUrl": "https://otisimg.oss-cn-hangzhou.aliyuncs.com/Pdf2Word/test.docx"
        }
      ],
      mainTaskIdList: [],
      showModal: false,
      imageSrc: '',
      cropperFile: null,
      radio: '正序',
      previewDocxVisible: false,
    };
  },
  methods: {
    isLoggendIn() {
      return this.$cookies.get('token') != null;
    },
    isNoDayUseCount() {
      console.log("isNoDayUseCount", this.$H.userInfo)
      if (this.$H.userInfo && this.$H.userInfo.vipLevel == 0 && this.$H.userInfo.dayUseCount == 0) {
        console.log("isNoDayUseCount true")
        return true;
      }

      console.log("isNoDayUseCount false")
      return false;
    },
    checkUserVip() {
      if (!this.isLoggendIn()) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (this.isNoDayUseCount()) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }  

      return true;
    },
    handleStartOCR() {
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0 || this.ocrInProgress) {
        return;
      }

      if (this.btnTxt == "合并下载") {
        this.mergeDown();
        return;
      }

      this.ocrInProgress = true;
      this.showLoading();
      this.getAliOssAuth(this.fileList);
    },
    handlePreview(file) {
      console.log("handlePreview", file, this.$refs.previewDocx);
      this.previewDocxVisible = true;
      this.$nextTick(() => {
        this.$refs.previewDocx.init(file);
      });
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];
      if (this.activeTab == 'pdf' && file && file.raw && file.raw.type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.fileList = [];
        return false;
      } else if (this.activeTab == 'image' && file.raw && !acceptedImageTypes.includes(file.raw.type)) {
        this.$message.error('请选择图片文件');
        this.fileList = [];
        return false;
      }


      if (this.ocrSuccess) {
        this.$message.error('请先清空当前任务！');
        return false;
      }

      if (this.activeTab == 'pdf') {
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }
        this.showFileList = false;
        this.pdf2Images(file.raw);
        return;
      }

      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      console.log("handleRemove");
      this.fileList = fileList;

      if (fileList.length == 0) {
        this.limitCount = MAX_IMAGE;
        this.ocrSuccess = false;
        this.btnTxt = "开始识别"
      }
    },
    handleExceed(files, fileList) {
      console.log("handleExceed files", files, typeof(files));
      if (files.length > this.limitCount) {
        this.$message.warning(`最多只能选择${this.limitCount}个文件`);
      }

      // console.log("handleExceed fileList", fileList);
      if (this.activeTab == 'pdf' && this.ocrSuccess === false) {
        
        console.log("handleExceed", files[0], typeof (files[0]), files[0].name, files[0].File);
        return;
      }

      if (this.ocrSuccess) {
        this.$message.error('请先清空再上传！');
      }
    },
    handleBeforeUpload() {
      console.log("handleBeforeUpload");
    },
    handleResetOCR() {
      this.ocrSuccess = false;
      this.fileList = [];
      this.limitCount = MAX_IMAGE;
      this.btnTxt = "开始识别";
      this.$nextTick(() => {
        this.radio = '正序';
      });
      
    },
    handlePreviewPicOcr(file) {
      this.curOcrFile = file;
      this.showLoading("识别中，请稍等...")

      this.handlePicOcr(file);
    },
    handlePicOcr(file) {
      console.log("handlePicOcr file", file);
      if (!this.checkUserVip()) {
        console.log("checkUserVip false");
        return;
      }

      // this.showLoading("开始上传文件");
      let fileList = [file];
      this.getAliOssAuth(fileList);
    },
    handlePicEdit(file) {
      console.log("handlePicEdit file", file);
      this.openModal(file);
    },
    handleRadioChange(radio) {
      console.log("handleRadioChange", radio)
      if (radio == '正序' || radio == '倒序') {
        if (this.fileList && this.fileList.length > 0)
          this.fileList.reverse();
      } else if (radio == '清空') {
        setTimeout(()=>{
          this.handleResetOCR();
        }, 500);
      }
    },
    async getAliOssAuth(fileList) {
      console.log("getAliOssAuth fileList", fileList);
      // this.showLoading("开始上传");
      if (fileList.length == 0) return;

      let res = await this.$api.getOssAuth({});
      if (res.data.code == 0) {
        const uploadPromises = fileList.map(file => this.uploadFile(res.data.data, file));
        await Promise.all(uploadPromises);
        console.log("All files uploaded successfully fileList", fileList);
        
        // this.showLoading("上传文件成功，开始OCR识别");
        const files = fileList.map(item => ({
          fileUrl: item.fileUrl
        }));
        if (this.$refs.previewDocx && !this.$refs.previewDocx.dialogVisible) {
          this.hideLoading();
        }
        
        this.startOCR(files); 
      } else {
        this.hideLoading();
        this.$message.error(res.data.msg);
      }
      
    },
    async uploadFile(data, file) {
      console.log("uploadFile", file);
      let obj = data;
      const client = new OSS({
        // yourRegion填写Bucket所在地域。Region填写为oss-cn-hangzhou。
        region: obj.region,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: obj.accessKeyId,
        accessKeySecret: obj.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: obj.securityToken,
        // 填写Bucket名称。
        bucket: obj.bucket
      })

      try {
        var date = new Date();
        const randomNum = Math.floor(Math.random() * 100000);
        const dir = `upload/${date.format("yyyyMMdd")}/${date.getTime()}-${randomNum}.jpg`;
        const result = await client.put(dir, file.raw);
        console.log('Upload successful', result);
        file.fileUrl = result.url;
      } catch (error) {
        console.error('Error uploading file', error);
      }
    },
    /**
     * 开始OCR识别
     * files: [{fileUrl: "xxxx"}]
     */
    async startOCR(files) {
      let res = await this.$api.execute({ files: files });
      console.log("startOCR", res)
      if (res.data.code === 0) {
        this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });
        files.map((item, index) => {
          let file = this.fileList.find(item2 => item2.fileUrl == item.fileUrl);
          if (index < res.data.executeCount) {
            file.ocrInProgress = true;
          }
          Vue.set(this.fileList, this.fileList.indexOf(file), file);
        });

        let intervalId = setInterval(() => {
          this.checkProgress(res.data.mainTaskId);
        }, 500);

        this.mainTaskIdList.push({ 
          mainTaskId: res.data.mainTaskId,
          intervalId: intervalId
         });
      } else if (res.data.code === 401) {
        this.$emit('notify', { message: '未登录', type: 'login' });
      } else if (res.data.code === 600) {
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
      } else {
        this.$message.error(res.data.msg);
        this.hideLoading();
      }
    },
    async checkProgress(mainTaskId) {
      // console.log("checkProgress")
      try {
        const data = await this.getFileProgress(mainTaskId);

        let intervalId = this.mainTaskIdList.find(item => item.mainTaskId == data.taskInfo.mainTaskId).intervalId;

        let allFinish = true;
        data.taskInfo.files.map(item => {
          if (item.errorCode == '') {
            allFinish = false;
          }
          let file = this.fileList.find((fileItem) => fileItem.fileUrl === item.fileUrl)
          if (file) {
            file.resultUrl = item.resultUrl;
            file.progress = item.progress;
            file.errorCode = item.errorCode;
            file.ocrSuccess = item.errorCode != '';
            file.ocrInProgress = !file.ocrSuccess;
            file.mainTaskId = mainTaskId;
            Vue.set(this.fileList, this.fileList.indexOf(file), file);
            
            if (this.$refs.previewDocx && this.$refs.previewDocx.dialogVisible && this.$refs.previewDocx.file == file) {
              console.log("checkProgress", this.$refs.previewDocx.file, file)
              if (file.errorCode != '' && file.errorCode != 'OK' && !this.$refs.previewDocx.showMessageError) {
                this.$refs.previewDocx.showMessageError = true;
                this.$message.error("识别失败");
              }

              if (file.errorCode == 'OK' && !this.$refs.previewDocx.ocrWordUrl) {
                this.$refs.previewDocx.init(file);
              }

              if (file.errorCode != '') {
                this.hideLoading();
              }
            }
          } else {
            console.log("checkProgress 没有找到对应图片")
          }
        })

        if (allFinish) {
          console.log('Progress is 100%. Stopping timer.');
          clearInterval(intervalId); // 停止定时器
          this.mainTaskIdList = this.mainTaskIdList.filter(item => item.intervalId !== intervalId);
          this.hideLoading();
          this.ocrSuccess = true;
          this.ocrInProgress = false;
          this.limitCount = this.fileList.length;
          this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });
          
          this.btnTxt = "合并下载";
        }
      } catch (error) {
        this.hideLoading();
        console.error('Error checking progress:', error);
      }
    },
    async getFileProgress(mainTaskId) {
      console.log("getFileProgress")
      let res = await this.$api.getfileProgress({ mainTaskId: mainTaskId });
      if (res.data.code === 0) {
        // this.showLoading(`当前进度${res.data.taskInfo.progress}%`);
        return res.data;
      }

      return 0;
    },
    async mergeDown() {
      let mainTaskIds = []
      this.fileList.map(item => {
        if (item.errorCode == 'OK') {
          mainTaskIds.push(item.mainTaskId);
        }
      })

      console.log("mergeDown", mainTaskIds, typeof(mainTaskIds));

      let res = await this.$api.mergeDown({ mainTaskIds: mainTaskIds});
      if (res.data.code === 0) {
        window.open(res.data.mergeUrl);
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async pdf2Images(file) {
      if (file && file.type === 'application/pdf') {
        this.showLoading();
        const fileReader = new FileReader();
        
        fileReader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          // 设置 PDF.js worker
          pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
          // 加载 PDF 文件
          this.pdfDoc = await pdfjsLib.getDocument(typedArray).promise;

          // 渲染所有页面为 JPG 图片
          this.fileList = [];
          this.showFileList = true;
          for (let i = 1; i <= this.pdfDoc.numPages; i++) {
            const jpgImage = await this.renderPageToImage(i);
            this.fileList.push({ url: jpgImage, raw: this.dataURItoBlob(jpgImage) });

            // const imageBlob = await fetch(jpgImage).then(res => res.blob());
            // new Compressor(imageBlob, {
            //   quality: 0.8, // 设置压缩质量
            //   success: (compressedResult) => {
            //     const blob = new Blob([compressedResult], { type: 'image/jpeg' });
            //     // console.log("pdf2Images", blob)
            //     this.fileList.push({ url: jpgImage, raw: blob });
            //   },
            // });
            if (i == this.pdfDoc.numPages) {
              this.hideLoading();
            }
          }
        };

        fileReader.readAsArrayBuffer(file);

        
      }
    },
    async renderPageToImage(pageNumber) {
      const page = await this.pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2.8 }); // 调整缩放比例

      // 创建一个临时 Canvas 来渲染页面
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      // 渲染页面到 Canvas
      await page.render(renderContext).promise;

      // 将 Canvas 转换为 JPG 图片
      return canvas.toDataURL('image/jpeg', 1.0);
    },
    showLoading(text) {
      // this.loading = true;
      // this.loadingText = text;
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      // this.loading = false;
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    openModal(file) {
      this.showModal = true;
      this.imageSrc = file.srcUrl || file.url;
      this.cropperFile = file;
    },
    closeModal() {
      this.showModal = false;
    },
    handleCroppedImage(croppedImage) {
      console.log('裁剪后的图片:', croppedImage);
      // 你可以在这里上传裁剪后的图片到 OSS 或其他存储
      if (!this.cropperFile.srcUrl) {
        this.cropperFile.srcUrl = this.cropperFile.url;
        this.cropperFile.srcRaw = this.cropperFile.raw;
      }
      this.cropperFile.url = croppedImage;
      this.cropperFile.raw = this.dataURItoBlob(croppedImage);
      Vue.set(this.fileList, this.fileList.indexOf(this.cropperFile), this.cropperFile);
      this.closeModal();
    }
  }
}
</script>

<style scoped>
.upload-area {
  padding: 5px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F7F7F7;
  border-radius: 10px;
  /* border: 1px solid rgb(214, 214, 214); */
}

.upload-demo {
  z-index: 1;
  width: 100%;
  height: 100%;
  /* border: 1px dashed green; */
}

/deep/ .el-upload .el-upload-dragger {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
}

/deep/ .el-upload {
  width: 100%;
  height: 100%;
}

/deep/ .el-upload__text {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #8E8E8E;
    line-height: 25px;
    text-align: center;
    font-style: normal;
}

/deep/ .el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 0;
  cursor: auto;
}

/deep/ .el-upload-list {
  position: absolute;
  left: 62px;
  top: 120px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 520px;
  /* pointer-events: none; */
}

/deep/ .el-upload-list::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari 和新版本的Edge */
}

/deep/ .el-upload-list--picture .el-upload-list__item {
  width: 96px;
  height: 130px;
  border-radius: 0;
  padding: 0;
  margin: 10px;
}

/deep/ .el-upload-list--picture .el-upload-list__item-thumbnail {
  width: 96px;
  height: 100%;
  margin-left: 0;

  /* width: 100px;
    height: 100px; */
}

/deep/ .el-upload-list__item-name {
  display: none;
}

/deep/ .el-upload-list--picture .el-upload-list__item-status-label {
  z-index: 1;
  display: none;
}

/deep/ .el-upload-list__item .el-icon-close {
  display: block;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  content: "\e6db";
  top: 0;
  right: 0;
  font-size: 20px;
  color: #fff;
  pointer-events: all;
}

/* /deep/ .el-upload .el-upload-dragger {
  pointer-events: none;
} */

/deep/ .btn-upload {
  position: absolute;
  bottom: 70px;
  width: 1200px;
  height: 60px;
  border: 1px solid #0769F6;
  border-radius: 10px;
  cursor: pointer;
  
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #0769F6;
    line-height: 60px;
    text-align: center;
    font-style: normal;

}

.tip-upload {
  margin-top: 386px;
}

.btn-ocr {
  cursor: pointer;
  z-index: 1;
  width: 400px;
    height: 50px;
  position: absolute;
  top: 41px;
  right: 32px;
  background: #CBCBCB;
  border-radius: 10px;
  
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    font-style: normal;
}

.radio-wrap {
  position: absolute;
  bottom: 160px;
  z-index: 1;
}

/deep/ .el-radio-button__inner {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #9BC0F5;
    line-height: 50px;
    text-align: center;
    font-style: normal;
    padding: 0px 22px;
    background: #E9F2FF;
    border: 0;
}

/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border: 0;
  border-radius: 10px 0 0 10px;
}

/deep/ .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 10px 10px 0;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background: #E9F2FF;
  box-shadow: 0 0 0 0 #409EFF;
  color: #0769F6;
}

/deep/ .el-radio-button__orig-radio:disabled:checked+.el-radio-button__inner {
  background: #E9F2FF;
  color: #9BC0F5;
}

/deep/ .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
  background: #E9F2FF;
  color: #9BC0F5;
}

/deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: 0 0 0 0 #409EFF;
}

.reset {
  right: 420px;
}

.btn-ocr-active {
  background: #0769F6;
}

/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}

/deep/ .el-dialog__header {
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__headerbtn {
  font-size: 37px;
  background: #000;
  right: -60px;
  top: 0;
  color: #fff;
  width: 60px;
  height: 60px;
}

/deep/ .el-dialog__close:hover {
  color: #fff;
}

/deep/ .el-dialog__close {
  color: #fff;
}


/deep/ .el-loading-spinner .path {
  stroke: #0769F6;
  /* 设置新的加载动画颜色 */
}

/deep/ .el-loading-spinner .el-loading-text {
  color: #0769F6;
}

/deep/ .docx-wrapper {
  position: relative;
  top: -45px;
  background: transparent;
  padding: 0;
  scale: 0.9;
}

/deep/ .docx-wrapper>section.docx {
  box-shadow: 0 0 0 0;
}
</style>