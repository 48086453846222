<template>
  <transition-group tag="ul" :class="[
      'el-upload-list',
      'el-upload-list--' + listType,
      { 'is-disabled': disabled }
    ]" name="el-list">
    <li v-for="file in files" :class="['el-upload-list__item', 'is-' + file.status, focusing ? 'focusing' : '']"
      :key="file.uid" tabindex="0" @keydown.delete="!disabled && $emit('remove', file)" @focus="focusing = true"
      @blur="focusing = false" @click="focusing = false">
      <slot :file="file">
        <img class="el-upload-list__item-thumbnail"
          v-if="file.status !== 'uploading' && ['picture-card', 'picture'].indexOf(listType) > -1" :src="file.url"
          alt="">
        <div v-if="file.ocrSuccess" class="ocr-wrap" @click="handleClick(file)">
          <div v-if="file.errorCode=='OK'" class="mask">
            <div class="loader-tips">识别完成</div>
            <div class="loader-tips">点击查看结果</div>
          </div>
          <div v-else class="failed mask">
            <div class="loader-tips">识别失败</div>
          </div>


          <div class="ocr-btn">
            <div class="loader-tips" @click.stop="handleReOcr(file)">重新识别</div>
          </div>
        </div>
        <div v-else class="ocr-wrap" @click="handleClick(file)">
          <div v-show=" file.ocrInProgress" class="mask">
            <div class="loader-tips">识别中...</div>
          </div>

          <div class="ocr-btn">
            <div class="loader-tips ocr-btn-w" @click.stop="handleEdit(file)">编辑</div>
            <div class="ocr-btn-line"></div>
            <div class="loader-tips ocr-btn-w" @click.stop="handleOcr(file)">识别</div>
          </div>
        </div>

        <a class="el-upload-list__item-name" @click="handleClick(file)">
          <i class="el-icon-document"></i>{{file.name}}
        </a>
        <label class="el-upload-list__item-status-label">
          <i :class="{
            'el-icon-upload-success': true,
            'el-icon-circle-check': listType === 'text',
            'el-icon-check': ['picture-card', 'picture'].indexOf(listType) > -1
          }"></i>
        </label>
        <i class="el-icon-close" v-if="!disabled" @click="$emit('remove', file)"></i>
        <i class="el-icon-close-tip" v-if="!disabled">{{ t('el.upload.deleteTip') }}</i>
        <!--因为close按钮只在li:focus的时候 display, li blur后就不存在了，所以键盘导航时永远无法 focus到 close按钮上-->
        <el-progress v-if="file.status === 'uploading'" :type="listType === 'picture-card' ? 'circle' : 'line'"
          :stroke-width="listType === 'picture-card' ? 6 : 2" :percentage="parsePercentage(file.percentage)">
        </el-progress>
        <span class="el-upload-list__item-actions" v-if="listType === 'picture-card'">
          <span class="el-upload-list__item-preview" v-if="handlePreview && listType === 'picture-card'"
            @click="handlePreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="$emit('remove', file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </slot>
    </li>
  </transition-group>
</template>
<script>
  import Vue from 'vue';
  import Locale from 'element-ui/src/mixins/locale';
  import ElProgress from 'element-ui/packages/progress';

  export default {

    name: 'ElUploadList',

    mixins: [Locale],

    data() {
      return {
        focusing: false
      };
    },
    components: { ElProgress },

    props: {
      files: {
        type: Array,
        default() {
          return [];
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      handlePreview: Function,
      handlePicOcr: Function,
      handlePicEdit: Function,
      listType: String
    },
    methods: {
      parsePercentage(val) {
        return parseInt(val, 10);
      },
      handleClick(file) {
        this.handlePreview && this.handlePreview(file);
      },
      handleOcr(file) {
        // console.log("handleOcr")
        if (file.ocrInProgress) return;

        this.handlePicOcr && this.handlePicOcr(file);
      },
      handleEdit(file) {
        if (file.ocrInProgress) return;

        this.handlePicEdit && this.handlePicEdit(file);
      },
      handleReOcr(file) {
        console.log("handleReOcr")
        file.ocrSuccess = false;
        file.resultUrl = '';
        Vue.set(this.files, this.files.indexOf(file), file);
      }
    }
  };
</script>

<style scoped>
.ocr-wrap{  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failed {
  background: rgba(228, 126, 126, 0.6)
}

.transparent {
  background: transparent;
}

.ocr-btn {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.ocr-btn-line {
  width: 2px;
  height: 10px;
  background: #fff;
}

.ocr-btn-w {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-tips {
  font-size: .8em;
    color: #ededed;
    letter-spacing: .1em;
    margin: 2px 0;
    text-shadow: #777 .5px .5px .5px;
    line-height: 1.2em;
}
</style>