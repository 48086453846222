<template>
  <div id="app">
    <Sidebar ref="sidebar" @tab-selected="handleTabSelection" />
    <div class="main-content">
      <UploadArea v-show="activeTab=='image'" :activeTab="activeTab" tabName="image" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <UploadArea v-show="activeTab == 'pdf'" :activeTab="activeTab" tabName="pdf" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import UploadArea from './components/UploadArea.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    UploadArea
  },
  data() {
    return {
      activeTab: 'pdf', // 初始状态
    };
  },
  mounted() {
    // console.log("App mounted")
    localStorage.clear();
  },
  methods: {
    handleTabSelection(selectedTab) {
      console.log('Selected tab:', selectedTab);
      console.log(window.screen.height, window.screen.width)
      this.activeTab = selectedTab;
    },
    handleNotification(notification) {
      console.log('Received notification:', notification);
      if (notification.type == 'login') {
        this.$refs.sidebar.showTipLogin();
      } else if (notification.type == 'userinfo') {
        this.$refs.sidebar.loginSuccess();
      } else if (notification.type == 'purchase') {
        this.$refs.sidebar.showTipPurchase();
      } 
    }
  },
}
</script>

<style>
#app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 30px 24px 0px;
}
</style>