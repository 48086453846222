import req from './http.js'
//定义接口

export const api = Object.assign(
  {
    getOssAuth: params => req('post', '/p2w/common/api/getOssAuth', params), 
    execute: params => req('post', '/p2w/common/api/execute', params),
    getfileProgress: params => req('get', '/p2w/common/api/getfileProgress', params),
    mergeDown: params => req('post', '/p2w/common/api/mergeDown', params),
    pdf2Imgs: params => req('post', '/p2w/common/api/pdf2Imgs', params),
    getPDF2ImgsProgress: params => req('post', '/p2w/common/api/getPDF2ImgsProgress', params),
    getNoticeList: params => req('post', '/p2w/common/api/getNoticeList', params),
    register: params => req('post', '/p2w/app/useraccount/register', params),
    login: params => req('post', '/p2w/app/useraccount/login', params),
    sendVerCode: params => req('get', '/p2w/app/useraccount/sendVerCode', params),
    sendFeedBack: params => req('post', '/p2w/app/useraccount/sendFeedBack', params),
    getUserInfo: params => req('post', '/p2w/app/useraccount/getUserInfo', params),
    getVipList: params => req('post', '/p2w/app/useraccount/getVipList', params),
    buyVip: params => req('post', '/p2w/app/payment/buyVip', params),
    checkOrderStatus: params => req('post', '/p2w/app/payment/checkOrderStatus', params),
    addPV: params => req('post', '/p2w/app/useraccount/addPV', params),
  },
);
export default api;
